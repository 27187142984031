.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #303030;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.knowledge-list {
  border-radius: 5px;
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 30px;
}

.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #ff5656;
}
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #000000;
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  color: rgb(0, 0, 0);
  background-color: #f1f1f1 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.makeStyles-root-187 {
  width: 80%;
  margin-top: 74px;
  margin-left: 251px;
}

.MuiTableCell-alignRight {
  text-align: left;
  flex-direction: row-reverse;
}

.MuiTableRow-root:nth-of-type(odd) {
  background: #dceeffd5;
}

.MuiToolbar-regular {
  min-height: 64px !important;
}

.Component-paginationToolbar-194 {
  width: 100%;
  padding: 0;
  background: azure;
}

a.external {
  font-size: 1em;
}

a.external[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 7px 3px 0px 5px;
}

.priority {
  border-radius: 0.5em;
  padding: 0.3em;
  font-weight: 500;
  font-size: 1em;
  /*background: -webkit-linear-gradient(90deg, #2f0a26, #414767);*/
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.21);
  transition: all 0.2s ease-in-out;
  text-align: center;
  color: black;
}

.status {
  border-radius: 0.5em;
  padding: 0.3em;
  font-weight: 500;
  font-size: 1em;
  /*background: -webkit-linear-gradient(90deg, #0a0606, #340542);*/
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.21);
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.link {
  display: inline-block;
  vertical-align: top;
  width: 90%;
  height: 34px;
  line-height: 36px;
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(0, 0, 0);
  letter-spacing: 0.1em;
  text-align: center;
  font-size: 0.8rem;
  margin: 10px;
  position: relative;
}

/* NavBar main menu hover */
.MuiListItem-button:hover {
  text-decoration: none;
  box-shadow: inset 0 5px 15px rgba(145, 92, 182, 0.4);
}

/* NavBar main menu hover */
.MuiListItem-button:active {
  text-decoration: none;
  background-color: rgba(47, 0, 255, 0.5) !important;
}

.underline {
  border: 2px solid transparent;
}
.underline::after {
  width: 0%;
  height: 2px;
  display: block;
  background-color: rgb(60, 34, 121);
  content: " ";
  position: absolute;
  top: 34px;
  left: 50%;
  -webkit-transition: left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.underline:hover::after {
  width: 100%;
  height: 2px;
  display: block;
  background-color: rgb(95, 97, 172);
  content: " ";
  position: absolute;
  top: 34px;
  left: 0;
}

.makeStyles-root-200 {
  max-width: 850px;
  width: 850px;
  margin-top: 24px;
  margin: 0 auto;
}

.makeStyles-root-395 {
  max-width: 850px;
  width: 850px;
  margin-top: 24px;
  margin: 0 auto;
}

.svgLogoIcon {
  width: 48px;
  height: 48px;
  margin-right: 15px;
  fill: rgb(44, 28, 58, 0.8);
}

.MuiListItemIcon-root {
  display: inline-flex;
  flex-shrink: 0;
  color: rgb(0, 0, 0) !important;
}

.sideBarIcon {
  color: blue;
  fill: rgb(255, 255, 255);
}

.makeStyles-toolbar-24 {
  min-height: 73px !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    padding-left: 292px;
    padding-right: 32px;
    max-width: 770px;
    right: 120px;
  }
}

@media (min-width: 600px) {
  .makeStyles-container-189 {
    padding-left: 212px;
    padding-right: 32px;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin: 20px;
}

.aside {
  display: flex;
  flex-flow: row wrap;
  margin-top: 16px;
  margin: 20px;
}

a {
  color: rgb(49, 49, 49);
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4em;
  outline: none;
  border-bottom: 2px solid transparent;
  -webkit-transition: border 300ms linear;
  -moz-transition: border 300ms linear;
  transition: border 300ms linear;
}

a:visited {
  color: rgb(26, 56, 30);
  text-decoration: none;
  font-weight: 600;
  outline: none;
}

a:hover,
a:focus {
  transition: all ease-in-out 0.3s;
  color: #1ed306;
  text-decoration: none;
  border-end-end-radius: 4px solid rgb(73, 248, 152);
}

.buttonList {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 100px;
  list-style-type: none;
}
.buttonList li {
  width: 33.3333%;
  float: left;
  padding: 1%;
  box-sizing: border-box;
  text-align: center;
}

.button {
  display: block;
  width: 320px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  -webkit-transform: translatez(0);
  transform: translatez(0);
  text-decoration: none;
  box-sizing: border-box;
  font-size: 18px;
}
.button:hover,
.button:focus {
  text-decoration: none;
}

.makeStyles-drawerPaper-7 {
  background-color: #2c1c3a !important;
}

.MuiListItemText-root {
  color: white !important;
}

.sweepButton {
  border-radius: 50px;
  padding: 26px;
  border: 2px solid rgba(255, 38, 38, 0.2);
  color: #f0f0f0;
  background: #000000;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.sweepButton .gradient {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 300%;
  height: 100%;
  bottom: auto;
  margin: auto;
  z-index: -1;
  background: black;
  background: -moz-linear-gradient(90deg, black 0%, #ff2626 94%, black 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, black),
    color-stop(94%, #ff2626),
    color-stop(100%, black)
  );
  background: -webkit-linear-gradient(90deg, black 0%, #ff2626 94%, black 100%);
  background: -o-linear-gradient(90deg, black 0%, #ff2626 94%, black 100%);
  background: -ms-linear-gradient(90deg, black 0%, #ff2626 94%, black 100%);
  background: linear-gradient(90deg, black 0%, #ff2626 94%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 );
  -webkit-transition: all 0s ease-out 0s;
  transition: all 0s ease-out 0s;
  -webkit-transform: translateX(-35%);
  -moz-transform: translateX(-35%);
  transform: translateX(-35%);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.sweepButton:hover,
.sweepButton:focus {
  border: 2px solid rgba(255, 38, 38, 0.8);
  color: #f0f0f0;
  -webkit-transition: all 0.3s ease-out 0.1s;
  transition: all 0.3s ease-out 0.1s;
}
.sweepButton:hover .gradient,
.sweepButton:focus .gradient {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.8s ease-out 0s;
  transition: all 0.8s ease-out 0s;
}

.MuiGrid-justify-xs-center {
  justify-content: flex-end !important;
}

.MuiGrid-spacing-xs-2 {
  width: 70% !important;
  margin: 0 auto !important;
}

.item0 {
  grid-area: top;
}
.item1 {
  grid-area: header;
}
.item2 {
  grid-area: prioritet;
}
.item3 {
  grid-area: oppdatert;
}
.item4 {
  grid-area: kategori;
}
.item6 {
  grid-area: profile;
}
.item7 {
  grid-area: alvorlighet;
}
.item8 {
  grid-area: reprodusere;
}
.item9 {
  grid-area: status;
}
.item10 {
  grid-area: tilleggsinfo;
}
.item11 {
  grid-area: oppsummering;
}
.item12 {
  grid-area: beskrivelse;
}
.item13 {
  grid-area: stegreprodusere;
}
.item14 {
  grid-area: vedlegg;
}
.item15 {
  grid-area: delegated;
}

.grid-container > div {
  text-align: left;
  font-size: 20px;
  border-radius: 4px;
  padding: 5px 2px;
}

.item1 {
  grid-area: header;
  align-self: center;
  font-weight: 600;
}

.MuiCardActions-root {
  display: block !important;
  padding: 8px;
  align-items: center;
}

/* For pdf */
.react-pdf__Page {
  margin-bottom: -10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 10px;
}
.react-pdf__Page__annotations annotationLayer {
  padding-bottom: 1px;
}
.react-pdf__Page__annotations.annotationLayer {
  padding: 1px;
}

.react-pdf__Page__canvas {
  margin: 0 auto !important;
}

.makeStyles-card-46 {
  margin: auto;
  max-width: 100%;
  margin-top: 40px;
  text-align: center;
  padding-bottom: 10px;
}

h4 {
  text-align: left;
  margin-left: 40px;
}
.wpcc-corners-round .wpcc-btn {
  font-size: 1em !important;
}

.wpcc-privacy {
  font-size: 1em !important;
}

hr.stil1 {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(5, 5, 5, 0.45),
    rgba(5, 5, 5, 0.45),
    rgba(0, 0, 0, 0)
  );
}
